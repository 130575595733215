import React, { useContext, useState, useEffect } from 'react'
import { Menu, Dropdown, Modal, Space, Input, Checkbox, Form, Select, Button, Tooltip ,DatePicker, message,Table,Collapse,notification, InputNumber} from 'antd'
import { SettingOutlined, SoundTwoTone, SoundFilled,BellOutlined } from '@ant-design/icons'
import { StateContext } from './state';
import { useHistory, useLocation } from 'react-router-dom'
import http from '../../utils/server'
import md5 from 'js-md5';
import menulist from './menu'
import { Link } from 'react-router-dom';
import './style.less'
import backUp from '../../../public/assets/backUp.png'
import sizeMax1 from '../../../public/assets/sizemax1.png'
import sizeMax2 from '../../../public/assets/sizemax2.png'
import sizeMini from '../../../public/assets/sizemini.png'
import closeForm from '../../../public/assets/closeForm.png'
import iconMeiHao from '../../../public/assets/meihao.ico'
// import icon from '../../../public/assets/ICON.ico'
import backgroundImage from '../../../public/assets/headBack.jpg'
// import apknewDown from '../../../public/assets/apknewDown.png'
import { Mode } from '../../App';
import {moment} from 'moment'
const { SubMenu } = Menu;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
function exportCsv(obj) {
    var title = obj.title;
    var titleForKey = Object.keys(obj.data[0]);
    var data = obj.data;
    var str = [];
    str.push(obj.title.join(",") + "\n");
    for (var i = 0; i < data.length; i++) {
        var temp = [];
        for (var j = 0; j < titleForKey.length; j++) {
            temp.push(data[i][titleForKey[j]]);
        }
        str.push(temp.join(",") + "\n");
    }
    var blob = new Blob(['\uFEFF' + str.join('')], {
        type: 'text/plain;charset=utf-8',
    });
    var downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = "export.csv";
    downloadLink.click();
}
function Config(props) {

    const [form] = Form.useForm();
    const [config, setConfig] = useState([]);
    const [coms, setComs] = useState([]);
    const [autoStart, setAutoStart] = useState(props.myConf.isAutoStart?true:false);
    const [regCode,setRegCode] = useState('');
    const [syncMon,setSyncMon]=useState('');
    const [isExitP,setIsExitP] = useState(props.myConf.isExitP?true:false);
    const [startTime, setStartTime] = useState('')//历史数据开始时间
    const [endTime, setEndTime] = useState('')
    useEffect(() => {
        
        http.get("/v1/getconfig", {}).then(data => {
            if (data.code === 0) {
                setConfig(data.data)
                form.setFieldsValue(data.data)
            }
        })
        http.get("/v1/getcom", {}).then(data => {
            if (data.code === 0) {
                setComs(data.data)
            }
        })
        http.get("/v1/regcode", {}).then(data => {
            if (data.code === 0) {
                setRegCode(data.data)
            }
        })
        
    }, [])
    
    return (
        <Form
            form={form}
            onFinish={(value) => {
                http.post("/v1/setconfig", { ...value, MODE: config.MODE ,
                    DBHOST:config.DBHOST,DBP:config.DBP,DBU:config.DBU,
                    HOST:config.HOST,HOSTACT:config.HOSTACT,HOSTPWD:config.HOSTPWD,STARTUP:autoStart,
                    EXITNEEDPWD:isExitP,BACKUPTIME:value.BACKUPTIME*1

                }).then(data => {
                    if (data.code === 0) {
                        form.setFieldsValue(data.data)
                    }
                })
            }}>
            <Form.Item
                label="公司名称"
                name="Title"
                >
                <Input />
            </Form.Item>

            {/* <Form.Item
                visible={false}
                label="数据库地址"
                name="DBHOST"
                rules={[{ required: true, message: '输入数据库地址' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                visible={false}
                label="数据库账号"
                name="DBU"
                rules={[{ required: true, message: '输入数据库账号' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                visible={false}
                label="数据库密码"
                name="DBP"
                rules={[{ required: true, message: '输入数据库密码' }]}>
                <Input />
            </Form.Item> */}
            <Form.Item
                label="本地端口"
                name="LOCALCOM"
                rules={[{ required: false, message: '选择端口' }]}>
                <Select
                    mode="multiple"
                    style={{ width: '100%', flex: 1 }}
                    placeholder="选择端口"
                    maxTagCount={'responsive'}
                >
                    {
                        coms.map(item => (
                            <Select.Option key={item} value={item}>{item}</Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            {/* <Form.Item
                visible={false}
                label="服务器地址"
                name="HOST"
                rules={[{ required: false, message: '输入服务器地址' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                visible={false}
                label="服务器账号"
                name="HOSTACT"
                rules={[{ required: false, message: '输入数据库密码' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                visible={false}
                label="服务器密码"
                name="HOSTPWD"
                rules={[{ required: false, message: '输入数据库密码' }]}>
                <Input />
            </Form.Item> */}
            <Form.Item
                label="注册密钥"
                name="KEY"
                rules={[{ required: false, message: '输入注册密钥' }]}>
                <Input />
            </Form.Item>
            <div>注册码：{regCode}</div>
            
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <Form.Item style={{marginTop:23}}
                    label="备份路径"
                    name="BACKUP"
                    rules={[{ required: false, message: '输入保存路径' }]}>
                    
                        <Input style={{width:300}} />
                </Form.Item>
                <Button onClick={() => {
                    if (window.CefSharp !== undefined) {
                        window.CefSharp.BindObjectAsync("bound");
                        let path =window.bound.getBackPath();
                        path!==""&&
                        form.setFieldsValue({ BACKUP: path })
                    }
                }}>选择路径</Button>
            </div>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                <Form.Item style={{marginTop:23}}
                    label="备份压缩"
                    name="BACKUPZIP"
                    valuePropName="checked"
                >
                    <Checkbox onChange={(e)=>{
                        form.setFieldsValue({
                            BACKUPZIP: e.target.checked
                            });
                    }}/>
                </Form.Item>
                <Form.Item style={{marginTop:23}}
                    label="备份时间"
                    name="BACKUPTIME">
                    <InputNumber min={0} max={23}  />
                </Form.Item>
            </div>
            
            
            <div style={{padding:10}}>
                <Checkbox checked={autoStart} onChange={(e) => {
                    if (window.CefSharp !== undefined) {
                        window.CefSharp.BindObjectAsync("bound");
                        let b= window.bound.setAutoStart(e.target.checked);
                        if(b){
                            setAutoStart(e.target.checked);
                        }
                    }
                }}>开机自启</Checkbox>
                <span style={{color:'red'}}>设置/取消开机自启功能时，需要关闭程序。右击程序图标选择以管理员身份运行</span>
            </div>
            <div> 
                {/* <DatePicker onChange={(date, dateString)=>{
                    setSyncMon(dateString)
                    console.log(dateString);
                }} /> */}

                <RangePicker style={{borderRadius:10}}
                    allowEmpty={[false,true]}
                    onChange={(dates, dateStrings) => {
                        console.log(dates,dateStrings);
                        if(dates===null){
                            setStartTime('');
                            setEndTime('');
                        }else{
                            setStartTime(dateStrings[0]);
                            setEndTime(dates[1]!==null?dateStrings[1]:new Date().toString('yyyy-mm-dd'));
                        }   
                    }}
                />
                <Button style={{marginLeft:20}} onClick={()=>{
                    // if(syncMon==='')return message.error('选择同步日期',2);
                    if(startTime==='')return message.error('选择同步日期',2);
                    // http.post("/v1/setsync", {mon:syncMon}).then(data => console.log(data));
                    http.post("/v1/settimesync", {start:startTime,end:endTime}).then(data => console.log(data));
                }}>同步历史数据</Button>
            </div>
            <div>
                <Checkbox checked={isExitP} onChange={e=>{
                    if (window.CefSharp !== undefined) {
                        window.CefSharp.BindObjectAsync("bound");
                        window.bound.setExitP(e.target.checked);
                        setIsExitP(e.target.checked)
                    }
                }}>退出需要密码</Checkbox>
            </div>
            <div style={{ textAlign: 'right' }}>
                <Button onClick={() => {
                    form.submit()
                }}>保存</Button>
            </div>
        </Form>
    )
}
function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};

function Header(props) {
    const { state, dispatch} = useContext(StateContext)
    let history = useHistory()
    let location= useLocation()
    const [showConfig, setShowConfig] = useState(false)
    const [repw, setrepw] = useState(false);
    const [npwo, setnpwo] = useState("");
    const [npw1, setnpw1] = useState("");
    const [npw2, setnpw2] = useState("");
    const [msg, setmsg] = useState("");
    const [menList,setMenList]=useState([]);
    const [showUserInfo,setShowUserInfo] = useState(false);
    const [showTitle, setShowTitle] = useState("");
    const [myConf,setMyConf] = useState({}); 
    const [devExp,setDevExp] = useState([]);  //显示数据
    const [devExp_,setDevExp_] = useState([]);//导出数据源 
    const [devWarn,setDevWarn] = useState([]); //显示数据
    const [devWarn_,setDevWarn_] = useState([]); //导出数据源 
    const [simExp,setSimExp] = useState([]);  //显示数据
    const [simExp_,setSimExp_] = useState([]);//导出数据源 
    const [simWarn,setSimWarn] = useState([]); //显示数据
    const [simWarn_,setSimWarn_] = useState([]); //导出数据源 
    const [showDev,setShowDev] = useState(false)
    const [showMode,setShowMode] = useState(1)
    const [msgCount,setMsgCount] = useState(0);//未读消息数量
    const [showMsgModal,setShowMsgModal] = useState(false);
    const [showMsg,setShowMsg] = useState('');
    const [nowTime,setNowTime] = useState('');
    const [isMax,setIsMax] = useState(false);
    const [isLoginOut,setIsLoginOut] = useState(false);
    const [isShowTitle,setIsShowTitle] = useState(false);
    const [pwdReg,setPwdReg] = useState('[\\w\\W]{2,}');
    const [mainTodo,setMainTodo] = useState('');
    let timer;
    function getDateTime(){
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? ('0' + m) : m;
        var d = date.getDate();
        d = d < 10 ? ('0' + d) : d;
        var h = date.getHours();
        h = h < 10 ? ('0' + h) : h;
        var minute = date.getMinutes();
        var second = date.getSeconds();
        minute = minute < 10 ? ('0' + minute) : minute;
        second = second < 10 ? ('0' + second) : second;
        setNowTime( h + ':' + minute +'\r\n'+y + '年' + m + '月' + d + '日');
    }
    useEffect(()=>{
        http.get("/v1/main", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'main', main: data.data })
                let tempList = [];
                menulist.filter(x=>x.local&& x.u_type>= data.data.type).map((item, index) =>{
                    if(item.child){
                        item.child.filter(x=>x.local&& x.u_type>= data.data.type&&data.data.role.indexOf(x.role)>-1).map(m=>{
                            tempList.push(m)
                        })
                    }else{
                        tempList.push(item)
                    }
                })
                setMenList(tempList)
                let todo = data.data.todo?data.data.todo.trim():''; 
                setMainTodo(todo);   
                dispatch({type:'mainTodo',mainTodo:todo})    
                let todoCon = todo===''?null:JSON.parse(todo)
                if(todoCon&&todoCon.isPwdReg){
                    let len = todoCon.pwdLen?todoCon.pwdLen:2;
                    let reg = `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)[\\w\\W]{${len},}`
                    if(todoCon.isIncludeLetterS){
                        reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w\\W]{${len},}`
                    }
                    if(todoCon.isIncludeSymbol){
                        reg =  `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
                    }if(todoCon.isIncludeLetterS&&todoCon.isIncludeSymbol){
                        reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
                    }
                    setPwdReg(reg);
                }
            } 
        })
        if(props.local){
            getDateTime();
            clearInterval(timer);
            timer=setInterval(() => {
                getDateTime();
            }, 5000);
            return ()=>clearInterval(timer);
        }
    },[])
    useEffect(()=>{
        localStorage.setItem("alarmSoundChange", true)
        if(props.local !== undefined){
            Date.prototype.getYMD = function(){
                let retDate = this.getFullYear()+"-";
                let mm = this.getMonth()+1;
                if(mm<10){
                    retDate+='0'+mm+"-"
                }else{
                    retDate+=mm+"-"
                }
                let dd = this.getDate();
                if(dd<10){
                    retDate+='0'+dd;
                }else{
                    retDate+=dd;
                }
                return retDate;
            }
            
            props.local !== undefined && http.get("/v1/getconfig", {}).then(data => {
                if (data.code === 0) {
                    setShowTitle(data.data.Title);
                }
            })
            if (window.CefSharp !== undefined) {
                window.CefSharp.BindObjectAsync("bound");
                let myConf =window.bound.getConfig();
                setMyConf(myConf);
                if (window.bound.getMaxWindow){
                    setIsShowTitle(true)
                    let isMax = window.bound.getMaxWindow();
                    setIsMax(isMax);
                }
                if(myConf.loadData){
                    // var d1Ms = new Date(myConf.loadTimeS).getTime();
                    // let d2Ms = new Date(myConf.loadTimeE).getTime();
                    // let dayMs = 1000*60*60*24;
                    // let timer = setInterval(() => {
                    //     if(d1Ms>d2Ms){
                    //         clearInterval(timer)
                    //         window.bound.setLoadData(false);
                    //     } else{
                    //         let day = new Date(d1Ms).getYMD();
                    //         console.log("同步时间"+day);
                    //         http.post("/v1/setsync", {mon:day}).then(data => console.log(data));
                    //         d1Ms+=dayMs;
                    //     }
                    // }, 60000);
                }
            }

        }else{
            // setMsgCount(3)
            // let msgStatus = JSON.parse(localStorage.getItem("msgStatus"));
            // setShowMsg(msgStatus);
            // let ttmsg= msgStatus.filter(x=>!x.isRead);
            // setMsgCount(ttmsg.length);
            http.get("/v1/device", {}).then(data => {
                if (data.code === 0) {
                  if(data.data!==null&&data.data.length>0){
                    let devList = data.data.filter(x=>x.node===1&&x.hide===0);
                    let now = new Date().getTime();
                    let devExpList = [];
                    let devExpList_ = [];
                    let devWarnList = [];
                    let devWarnList_ = [];
                    let columnId = 1;
                    let simExpList = [];
                    let simExpList_ = [];
                    let simWarnList = [];
                    let simWarnList_ = [];
                    devList.map(m=>{
                        let exp = new Date(m.expire).getTime();
                        let simExp = new Date(m.iccid_expire).getTime();
                        if (exp<now && (exp+100*24*60*60*1000)>now){
                            devExpList_.push(m)
                            if(devExpList.findIndex(x=>x.user_name===m.user_name)===-1){
                                devExpList.push({id:columnId,user_name:m.user_name,devs:[m],exp_date:m.expire})
                                columnId++
                            }else{
                                devExpList.map((n,i)=>{
                                    if(n.user_name===m.user_name){
                                        devExpList[i].devs.push(m)
                                        if(new Date(devExpList[i].exp_date).getTime()>exp){
                                            devExpList[i].exp_date=m.expire
                                        }
                                    }
                                })
                            }
                        }
                        columnId=1;
                        if (simExp<now && (simExp+30*24*60*60*1000)>now){
                            simExpList_.push(m)
                            if(simExpList.findIndex(x=>x.user_name===m.user_name)===-1){
                                simExpList.push({id:columnId,user_name:m.user_name,devs:[m],exp_date:m.iccid_expire})
                                columnId++
                            }else{
                                simExpList.map((n,i)=>{
                                    if(n.user_name===m.user_name){
                                        simExpList[i].devs.push(m)
                                        if(new Date(simExpList[i].exp_date).getTime()>exp){
                                            simExpList[i].exp_date=m.iccid_expire
                                        }
                                    }
                                })
                            }
                        }
                        columnId=1;
                        if (exp > now && (now+30*24*60*60*1000)>exp){
                            devWarnList_.push(m)
                            if(devWarnList.findIndex(x=>x.user_name===m.user_name)===-1){
                                devWarnList.push({id:columnId,user_name:m.user_name,devs:[m],exp_date:m.expire})
                                columnId++
                            }else{
                                devWarnList.map((n,i)=>{
                                    if(n.user_name===m.user_name){
                                        devWarnList[i].devs.push(m)
                                    }
                                    if(new Date(devWarnList[i].exp_date).getTime()>exp){
                                        devWarnList[i].exp_date=m.expire
                                    }
                                })
                            }
                        }

                        columnId=1;
                        if (simExp > now && (now+30*24*60*60*1000)>simExp){
                            simWarnList_.push(m)
                            if(simWarnList.findIndex(x=>x.user_name===m.user_name)===-1){
                                simWarnList.push({id:columnId,user_name:m.user_name,devs:[m],exp_date:m.iccid_expire})
                                columnId++
                            }else{
                                simWarnList.map((n,i)=>{
                                    if(n.user_name===m.user_name){
                                        simWarnList[i].devs.push(m)
                                    }
                                    if(new Date(simWarnList[i].exp_date).getTime()>exp){
                                        simWarnList[i].exp_date=m.iccid_expire
                                    }
                                })
                            }
                        }
                    })
                    devExpList.sort((a,b)=>a.exp_date>b.exp_date);
                    devWarnList.sort((a,b)=>a.exp_date>b.exp_date);
                    simExpList.sort((a,b)=>a.exp_date>b.exp_date);
                    simWarnList.sort((a,b)=>a.exp_date>b.exp_date);
                    setDevExp(devExpList)
                    setDevExp_(devExpList_)
                    setDevWarn(devWarnList)
                    setDevWarn_(devWarnList_)
                    setSimExp(simExpList)
                    setSimExp_(simExpList_)
                    setSimWarn(simWarnList)
                    setSimWarn_(simWarnList_)
                  }
                }
              })
        }
        // let el = document.getElementById('qrcode')
    },[])

    useEffect(()=>{
        let todo = state.mainTodo;
        setMainTodo(todo);
        let todoCon = todo===''?null:JSON.parse(todo)
        if(todoCon&&todoCon.isPwdReg){
            let len = todoCon.pwdLen?todoCon.pwdLen:2;
            let reg = `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)[\\w\\W]{${len},}`
            if(todoCon.isIncludeLetterS){
                reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[\\w\\W]{${len},}`
            }
            if(todoCon.isIncludeSymbol){
                reg =  `^((?=.*[a-z])|(?=.*[A-Z]))(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
            }if(todoCon.isIncludeLetterS&&todoCon.isIncludeSymbol){
                reg = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[\\w\\W]{${len},}`
            }
            setPwdReg(reg);
        }
    },[state.mainTodo])
    const columns = [
        {
            title:'到期日期',
            align:'center',
            dataIndex:'exp_date'
        },
        {
            title: '用户名称',
            align: 'center',
            dataIndex: 'user_name',
        },
        {
            title: '设备数量',
            align: 'center',
            render: (text, record) => (
                <div>
                    {
                        record.devs.length
                    }
                </div>
            ),
        },
        {
            title: '操作',
            width: 150,
            dataIndex: 'operation',
            key: 'id',
            align: 'center',
            render: (text, record) => (
                <div style={{display:'flex',flexDirection:'row'}}>
                    {/* <Button size='small' style={{ marginLeft: '10px',borderRadius:10 }} type="primary" onClick={() => {

                    }}>
                        不再提示
                    </Button> */}
                    <Button size='small' type='primary' danger style={{ marginLeft: '10px' ,borderRadius:10}} onClick={() => {
                        setShowDev(false)
                        let pathname = location.pathname;
                        if(pathname==='/manage/device'){
                            dispatch({type: 'selUser_dev', selUser_dev:record.user_name})
                        }else{
                            let classObj = document.getElementsByClassName('ant-menu-item ant-menu-item-selected')[0];
                            // let toclassObj = document.getElementsByClassName('ant-menu ant-menu-root')[0].children[2].children[1].children[3];
                            classObj.classList.remove('ant-menu-item-selected') 
                            // toclassObj.classList.add('ant-menu-item-selected') 
                            // console.log(toclassObj);
                            dispatch({type: 'selUser_dev', selUser_dev:''})
                            history.push({pathname:'/manage/device',state:{user_name:record.user_name,exp:showMode}})
                        }                       
                    }} disabled={state.main.role.indexOf('307')===-1}>
                        管理
                    </Button>
                    {/* <Link to={{pathname:'/manage/device',state:{user_name:record.user_name,exp:showMode}}} >管理</Link> */}
                   
                </div>
            ),
        }
    ]

    return (
//72E4FF
        // <div style={{ width:'100%', height: props.local !== undefined?92: 46,  boxShadow: '0 1px 4px rgb(0 21 41 / 8%)', zIndex: 99 ,backgroundImage:`url(${backgroundImage})`,backgroundSize:'cover'}}>
        <div style={{ width:'100%',   zIndex: 99 ,backgroundColor:'#2569D8'}}>
            
            {
                props.local !== undefined &&
                <div className='localTitleCon'>
                    <div>
                        {
                            isShowTitle&&
                            <div className='localTitle'>
                                {/* <img src={icon}alt="" srcset="" style={{height:46}} /> */}
                                <img src={iconMeiHao}alt="" srcset="" style={{height:46}} />
                                <div style={{whiteSpace:'pre-wrap',textAlign:'center',color:'#72E4FF'}}>{nowTime}</div>
                            </div>
                        }
                    </div>
                    
                    <div >
                        <span style={{fontSize:'25px',color:'#72E4FF'}}>{showTitle}温湿度监测系统</span>
                    </div>
                    <div>
                        {
                            isShowTitle&&
                            <div className='localTitle' style={{paddingTop:5}}>
                                <div style={{width:40}}></div>
                                <Tooltip title={'最小化'}>
                                    {/* <span style={{fontSize:24,fontWeight:'bold'}}>_</span> */}
                                    <img src={sizeMini} alt="" srcset=""  style={{height:24}} onClick={e=>{
                                        window.CefSharp.BindObjectAsync("bound");
                                        window.bound.minWindow()
                                    }}/>
                                </Tooltip>
                                <Tooltip title={isMax?'恢复':'最大化'}>
                                    <img src={isMax?sizeMax1:sizeMax2} alt="" srcset=""  style={{height:24}} onClick={e=>{
                                        window.CefSharp.BindObjectAsync("bound");
                                        window.bound.setMaxWindow()
                                        setIsMax(!isMax)
                                    }}/>
                                </Tooltip>
                                <Tooltip title={'关闭'}>
                                    <img src={closeForm} alt="" srcset=""  style={{height:24}} onClick={e=>{
                                        window.CefSharp.BindObjectAsync("bound");
                                        window.bound.closeWindow()
                                    }}/> 
                                </Tooltip>
                            </div>
                        }
                    </div>
                    
                </div>
            }
            <div style={{display: 'flex', justifyContent: 'space-between',backgroundColor: props.local !== undefined && '#4ACA6D',borderTop:'1px solid #ccc',height: props.local !== undefined?46: 50}}>
            {/* <div style={{display: 'flex', justifyContent: 'space-between',backgroundColor: props.local !== undefined && '#1890ff',borderTop:'1px solid #ccc'}}> */}
                {props.local !== undefined ?
                    <div className='localMenu'>
                        <Menu style={{ flex: 1 }} mode="horizontal" >
                            {

                                menList.map((item, index) => 
                                   <Menu.Item key={item.key} icon={state.resolution.width>1440 &&item.icon}><Link to={item.path}>{item.name}</Link></Menu.Item>
                                )       
                            }
                        </Menu>
                    </div> :
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center',}}>
                        {state.main.logo !== '' ? <img src={`${process.env.PUBLIC_URL}${state.main.logo}`} style={{ width: 40, height: 38 }} /> : <div></div>}
                        <div style={{ flex:2, marginLeft: 50 }}><span style={{ fontSize: 20, fontWeight: 500, color:'#fff',lineHeight:'80px',fontFamily:'FZRuiZhengHei_GBK'}}>{state.main.title}</span></div>
                        <marquee style={{flex:1}}>
                            {
                                devWarn.length>0&&

                                <span style={{color:'orange',fontSize: 16, fontWeight: 'bold',cursor:'pointer',marginLeft:10}} onClick={()=>{
                                    setShowDev(true)
                                    setShowMode(2)
                            }}>{`您有${devWarn_.length}台设备即将到期点击查看详情`}</span>

                            }
                        </marquee>
                        <marquee style={{flex:1}}>
                            {
                                devExp.length>0&&
                                <span style={{color:'red',fontSize: 16, fontWeight: 'bold',cursor:'pointer'}} onClick={()=>{
                                    setShowDev(true)
                                    setShowMode(1)
                                }}>{`您有${devExp_.length}台设备已到期点击查看详情`}</span>

                            }
                        </marquee>
                        
                    </div>
                }
                {/* {
                    props.local !== undefined&&
                    <div style={{marginTop:"5px",display:'flex',flexGrow:'1',paddingLeft:'50px'}}><span style={{fontSize:'20px',color:'#fff'}}>{showTitle}</span></div>
                } */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {
                        // props.local === undefined&&
                        // <Badge count={msgCount} size="small">
                        //     <Button shape="circle" icon={<BellOutlined />} size='small' onClick={
                        //         ()=>{
                        //             setShowMsgModal(true)
                        //         }
                        //     }></Button>
                        // </Badge>
                    }
                    {
                        props.local === undefined &&
                        <Dropdown
                            overlay={<Menu>
                                    <Menu.Item key="0">
                                        <div style={{width:100,height:100}} id="qrcode">加载中...</div>
                                    </Menu.Item>
                                    <Menu.Item key="1">
                                        <a href={process.env.PUBLIC_URL+"/static/appDownLoad/app-release.apk"} download>apk(点击下载)</a>
                                    </Menu.Item>
                            </Menu>}>
                            <a style={{ margin: 10, color:'#fff',fontFamily:'PingFang SC',fontSize:'12px',lineHeight:'80px' }} onMouseOver={()=>{
                                setTimeout(() => {
                                    let el = document.getElementById('qrcode')
                                    if(el!==null){
                                        el.innerHTML='';
                                        var qrcode = new window.QRCode(el, {
                                            width : 100,
                                            height : 100,
                                            colorDark:"#1890ff"
                                        });
                                        qrcode.makeCode('http://'+window.location.host+"/static/appDownLoad/down.html");
                                    }
                                }, 1000);
                            }} 
                            
                            >APP下载</a>
                        </Dropdown>
                    }
                    {/* <Tooltip title='用户信息'> */}
                    {/* <Button type='text' onClick={()=>setShowUserInfo(true)}>
                        {state.main.account}
                    </Button> */}
                    <Tooltip title={state.alarmSound?'点击开启语音报警':'点击关闭语音报警'}>
                        <div  style={{ display: 'flex', alignItems: 'center' ,justifyContent:'center'}} onClick={()=>{localStorage.setItem("alarmSoundChange", state.alarmSound);dispatch({type:'alarmSound',alarmSound:!state.alarmSound});}}>
                        {
                            // state.alarmSound?<SoundFilled size="40"/>:
                            <SoundTwoTone theme="two-tone" size="30" twoToneColor={state.alarmSound?'#fff':'#000'} /> 
                        }
                        </div>
                    </Tooltip>
                    {
                        (props.local !== undefined &&(state.main.type<102||myConf.loadData)) &&
                        <Tooltip title={'单击备份数据'}>
                            <div className='localButton' style={{ textAlign: 'center' }}
                                onClick={() => {
                                    http.post("/v1/startbackup", {}).then(data => console.log(data));
                                }}>
                                <img style={{ width: 20, height: 20, marginTop: 12 }} src={backUp} alt="" />
                            </div>
                        </Tooltip>
                    }
                    {
                        (props.local !== undefined &&state.main.type<102 )&&
                        <Tooltip title={'参数配置'}>
                            <div className='localButton' onClick={() => {
                                if (window.CefSharp !== undefined) {
                                    window.CefSharp.BindObjectAsync("bound");
                                    let myConf =window.bound.getConfig();
                                    setMyConf(myConf);
                                }
                                setShowConfig(true)
                                
                            }}>
                                <SettingOutlined style={{ width: 46, height: 46, paddingTop: 16 }} />
                            </div>
                        </Tooltip>
                    }
                    <Dropdown
                        trigger={'click'}
                        overlay={<Menu>
                            <Menu.Item key="0">
                                <div style={{ border: "0px none", cursor: 'pointer' }} onClick={() => {
                                    setrepw(true)
                                }}>修改密码</div>
                            </Menu.Item>
                            <Menu.Item key="1">
                                <div style={{ border: "0px none", cursor: 'pointer' }} onClick={() => {
                                    // localStorage.clear();
                                    if(Mode!=='soc' && myConf.isExitP){
                                        setIsLoginOut(true)
                                    }else{
                                        dispatch({type:'super',super:0})
                                        dispatch({type:'setState',setState:0})
                                        http.post("/v1/logout", {}).then()
                                        history.push('/login')
                                    }
                                }}>退出登录</div>
                            </Menu.Item>
                        </Menu>}>
                        {
                             Mode==='soc'?
                             <a style={{ margin: 10, color:'#fff',fontFamily:'PingFang SC',fontSize:'12px',lineHeight:'80px' }} >{state.main.account}</a>
                             :<a style={{ margin: 10, color: '#fff' }} >{state.main.account}</a>
                        }
                        
                    </Dropdown>
                    {/* </Tooltip> */}

                    <Modal maskClosable={false} title="修改参数"
                        destroyOnClose
                        visible={showConfig}
                        closable={true}
                        footer={null}
                        onCancel={() => {
                            setShowConfig(false)
                        }}>
                        <Config myConf={myConf} onfinish={() => {
                            console.log("object")
                        }} />
                    </Modal>
                    <Modal maskClosable={false} title="修改密码"
                        visible={repw}
                        destroyOnClose
                        closable={false} cancelText='取消'
                        okText='确定'
                        onOk={() => {
                            // let regex =(myConf&&myConf.isPwdL)? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/;
                            let regex = new RegExp(pwdReg);
                            (regex.test(npw1)&&npw1 === npw2 && npw1.length > 2) &&
                                http.post("/v1/userspassword", { who: state.main.account, password: md5(npwo), account: state.main.account, newpassword: md5(npw1) }).then(data => {
                                    if (data.code === 0) {
                                        Modal.success({
                                            title: "成功",
                                            content: "密码修改成功,请重新登录",
                                            onOk() { history.push('/login') },
                                        });
                                    } else {
                                        Modal.warning({
                                            title: data.code,
                                            content: data.msg,
                                        });
                                    }
                                })

                        }}
                        onCancel={() => {
                            setnpwo("")
                            setnpw1("")
                            setnpw2("")
                            setmsg("")
                            setrepw(false)
                        }}>
                        <Space direction="vertical">
                            <Input.Password addonBefore="输入旧密码" placeholder="input password" value={npwo}
                                onChange={(e) => {
                                    setnpwo(e.target.value)

                                }} />
                            <Input.Password addonBefore="输入新密码" placeholder="input password" value={npw1}
                                onChange={(e) => {
                                    let ss = e.target.value
                                    // let regex =(myConf&&myConf.isPwdL)? /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[\w\W]{8,}$/:/[\w\W]{2,}/;
                                    let regex = new RegExp(pwdReg);
                                    if(!regex.test(ss)){
                                        let todo = mainTodo;
                                        let todoCon = todo===''?null:JSON.parse(todo)
                                        let msg = '密码必须为2位以上'
                                        if(todoCon&&todoCon.isPwdReg){
                                            let len = todoCon.pwdLen?todoCon.pwdLen:2;
                                            msg = `密码必须为${len}位以上,包含数字和字母`;
                                            if(todoCon.isIncludeLetterS){
                                                msg = `密码必须为${len}位以上,包含数字和大小写字母`;
                                            }
                                            if(todoCon.isIncludeSymbol){
                                                msg = `密码必须为${len}位以上,包含数字、字母、特殊符号`;
                                            }if(todoCon.isIncludeLetterS&&todoCon.isIncludeSymbol){
                                                msg = `密码必须为${len}位以上,包含数字、大小写字母、特殊符号`;
                                            }
                                        }
                                        setmsg(msg);
                                        ss='';
                                        // setmsg((myConf&&myConf.isPwdL)?"密码长度必须为8位以上,必须包含大小字母、数字、特殊字符":"密码必须为2位以上");
                                        // ss='';
                                    }else{
                                        // msg!==""&&setmsg("");
                                        (npw2!==""&&ss != npw2 )? setmsg("两次输入的密码不相同") : setmsg("")
                                    }
                                    setnpw1(e.target.value)
                                    
                                }} />
                            <Input.Password addonBefore="重输新密码" placeholder="input password" value={npw2}
                                onChange={(e) => {
                                    let ss = e.target.value
                                    ss !== npw1 ? setmsg("两次输入的密码不相同") : setmsg("")
                                    setnpw2(e.target.value)

                                }} />
                            <font size="3" color="red">{msg}</font>
                        </Space>
                    </Modal>
                    <Modal maskClosable={false} title="退出登录"
                        visible={isLoginOut}
                        destroyOnClose
                        closable={false} cancelText='取消'
                        okText='确定'
                        onOk={() => {
                           if(md5(npwo)===state.main.password){
                                dispatch({type:'super',super:0})
                                dispatch({type:'setState',setState:0})
                                http.post("/v1/logout", {}).then()
                                history.push('/login')
                                if (window.CefSharp !== undefined) {
                                    window.CefSharp.BindObjectAsync("bound");
                                    window.bound.getIsLogin&&window.bound.getIsLogin(false);
                                }
                           }else{
                            setnpwo("")
                            notification['error']({
                                message: '密码错误',
                                description: '请输入正确密码',
                                duration: 2,
                            });
                           }
                        }}
                        onCancel={() => {
                            setnpwo("")
                            setIsLoginOut(false)
                        }}>
                        <Space direction="vertical">
                            <Input.Password addonBefore="请输入密码" placeholder="input password" value={npwo}
                                onChange={(e) => {
                                    setnpwo(e.target.value)
                                }} />
                            
                        </Space>
                    </Modal>
                </div>
            </div>
            
            <Modal maskClosable={false} title={
                showMode===1?'到期设备信息详情':showMode===2?'即将到期设备信息详情':'设备信息详情'
                } width={'85vw'} 
                centered visible={showDev} destroyOnClose={true} 
                footer={null}
                onCancel={() => {
                    setShowDev(false)
                }}
            >
                <div style={{float:'right',marginBottom:10}}>
                    <Button type='primary' onClick={()=>{
                        let exDev=showMode===1?devExp_:devWarn_;
                        exDev.map((m,i)=>{
                            let temp = {};
                            temp.id = i;
                            temp.user_name = m.user_name;
                            temp.district_name = m.district_name;
                            temp.m_name = m.m_name;
                            temp.sn = m.sn;
                            temp.node = m.node;
                            temp.name = m.name;
                            temp.type= m.type===201?'监测主机':m.type===202?'保温箱':m.type===203?'车载':m.type===209?'902保温箱':m.type===208?'无限设备':m.type===205?'压力传感器':'其他'
                            temp.time = m.time;
                            temp.expire = m.expire;
                            temp.sim = m.sim;
                            // temp.iccid = m.iccid;
                            // temp.iccid_expire = m.iccid_expire;
                            exDev.push(temp)
                        })
                        exportCsv({
                            title: ["序号","用户名","区域名称","设备名称",'设备编号','测点ID',"测点名称","设备类型",'添加时间','到期时间','sim卡号'],
                            data: exDev
                        })
                    }} disabled={state.main.role&&state.main.role.indexOf('307')===-1} >导出</Button>
                </div>
                <Table bordered pagination={{ hideOnSinglePage: true }} rowKey={record => record.id} columns={columns} size='small' dataSource={showMode===1?devExp:devWarn} 
            />
            </Modal>
            <Modal maskClosable={false} title="通知中心"
                visible={showMsgModal}
                footer={null}
                destroyOnClose
                width={'80%'}
                onCancel={() => {
                    setShowMsgModal(false);
                }}>
                    <Collapse defaultActiveKey={['1']} onChange={e=>{
                            let msgStatus = JSON.parse(localStorage.getItem("msgStatus"));
                            console.log('msgStatus',msgStatus);
                            if(msgStatus){
                                // console.log('msgStatus',);
                                let tt = msgStatus.filter(x=>x.id===2)[0];
                                console.log(tt);
                                if(!tt.isRead){
                                    setMsgCount(msgCount-1)
                                }
                                msgStatus[1].isRead = true; 
                            }else{
                                setMsgCount(msgCount-1)
                                msgStatus=[{'id':1,'isRead':true},{'id':2,'isRead':false}]
                            }
                            localStorage.setItem("msgStatus", JSON.stringify(msgStatus));
                        }}>
                        <Panel header="This is panel header 1" key="1">
                            <p>{"text"}</p>
                        </Panel>
                        <Panel header="This is panel header 2" key="2">
                            <p>{'text'}</p>
                        </Panel>
                        <Panel header="This is panel header 3" key="3">
                            <p>{'text'}</p>
                        </Panel>
                    </Collapse>

            </Modal>
        </div>

    )
}


export default Header