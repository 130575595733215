import React, { useContext, useState, useEffect, createContext ,useMemo } from 'react'
import { Table, Button, Modal, Descriptions, DatePicker, Select, TreeSelect,Space,Spin, Tooltip ,Input} from 'antd'
import { StateContext, OPERATIONTYPE, TimeFormatSeconds } from '../../../state';
import { SearchOutlined } from '@ant-design/icons';
import http from '../../../../../utils/server'
import moment from 'moment';
import { Mode } from '../../../../../App';
import { color } from 'echarts';

function formatDateTimes(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
};
function formatDate(inputTime) {
    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    return y + '-' + m + '-' + d;
};
const DetailsCreate = createContext()
const { RangePicker } = DatePicker;
function DeTailsModal() {
    const logs = useContext(DetailsCreate)
    const { state, dispatch } = useContext(StateContext)


    const columns = [{
        title: '字段名',
        dataIndex: 'field_name',
        key: 'field_name',
        align: 'center',
    },
    {
        title: '字段注释',
        dataIndex: 'field_comment',
        key: 'field_comment',
        align: 'center',
    },
    {
        title: '新值',
        dataIndex: 'new_value',
        key: 'new_value',
        align: 'center',
        ellipsis: true,
        render: (text, record) => (
            record.new_value.String
        )
    },
    {
        title: '旧值',
        dataIndex: 'old_value',
        key: 'old_value',
        align: 'center',
        ellipsis: true,
        render: (text, record) => (
            record.old_value.String
        )
    },
    {
        title: '说明',
        dataIndex: 'explained',
        key: 'explained',
        align: 'center',
        render: (text, record) => (
            record.explained.String
        )
    }
    ]

    return (<div >
        <Descriptions bordered size='small'
            column={2} >
            <Descriptions.Item label='操作日期' > {logs.details.operation_date} </Descriptions.Item>
            <Descriptions.Item label='操作表名' > {logs.details.operation_table} </Descriptions.Item>
            <Descriptions.Item label='操作名' > {logs.details.operation_name} </Descriptions.Item>
            <Descriptions.Item label='操作类型' > {OPERATIONTYPE[logs.details.operation_type]} </Descriptions.Item>
            <Descriptions.Item label='所属工程' > {logs.details.project_id} </Descriptions.Item>
            <Descriptions.Item label='操作人' > {
                state.systemuser.filter(x => { return x.id === logs.details.user_id }).length > 0 ? state.systemuser.filter(x => { return x.id === logs.details.user_id })[0].username : '无'
            } </Descriptions.Item>
            <Descriptions.Item label='说明' > {logs.details.explained.String} </Descriptions.Item>
        </Descriptions>
        <h1 > 详情 </h1>             {
            <Table bordered pagination={
                { hideOnSinglePage: true }}
                rowKey={record => record.id}
                columns={columns}
                size='small'
                dataSource={state.operationdetails}
            />
        }

    </div>
    )
}

function Operation() {
    const { state, dispatch } = useContext(StateContext)
    const [showDetailsModel, setShowDetailsModel] = useState(false)
    const [details, setDetails] = useState([])
    const [logType, setLogType] = useState(-1)
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('');
    const [selUser, setSelUser] = useState(0);
    const [oType, setOType] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [pType,setPType]=useState([]);
    const [logData,setLogData] = useState([]);
    const userSerch = React.createRef();
    const [signEnable,setSignEnable] = useState(false);
    useEffect(async() => {
        setIsLoading(true);
        let userLogin = state.main;
        await http.get("/v1/users", {}).then(data => {
            if (data.code === 0) {
                dispatch({ type: 'systemuser', systemuser: data.data.sort((a,b)=>a.name.localeCompare(b.name,'zh')) })
            }
        })
        http.get("/v1/userlog?starttime="+formatDateTimes(new Date().getTime()-1000*60*60*24)+'&endtime='+formatDateTimes(new Date().getTime()), {}).then(data => {
            setIsLoading(false)
            if (data.code === 0) {
                let logData = data.data.filter(x=>x.type!==303)
                if(state.main.type>101){
                    logData =logData.filter(x=>!(x.type===305&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1))&&!(x.type===301&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1)||x.log.indexOf('settimesync')!==-1))
                }
                logData.sort((a,b)=>Date.parse(b.time)- Date.parse(a.time))
                let temp = logData.filter(x=>!((x.type===305||x.type===901)&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1))&&!(x.type===301&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1)||x.log.indexOf('settimesync')!==-1))
                dispatch(
                    Mode!=='soc'?{ type: 'operation', operation:temp  }:
                    { type: 'operation', operation: logData })
                    setLogData(Mode!=='soc'?temp : logData)
            }
        })
        http.get("/v1/permissiontype", {}).then(data => {
            if (data.code === 0) {
                // console.log(data.data);
                dispatch({ type: 'permissiontype', permissiontype: data.data })
                let arrs = [{text:'登录',value:101}];
                data.data.map(m=>{
                    m.code!==303&&
                    arrs.push({text:m.value,value:m.code})
                })
                setPType(arrs);
            }
        })
    }, []);
    useEffect(()=>{
        let todo = state.mainTodo;
        let todoCon = todo===''?null:JSON.parse(todo)
        if(todoCon&&todoCon.sginEnable){
            setSignEnable(true);
        }
    },[state.mainTodo])
    const columns = 
    [{
        title: '操作日期',
        dataIndex: 'time',
        align: 'center',
        width:160,
        sorter: (a, b) =>Date.parse(a.time)  -Date.parse(b.time) ,
    },
    {
        title: '用户类型',
        colSpan:Mode==='soc'?1: 2,
        dataIndex: 'usertype',
        width:100,
        align: 'center',
        render:(text,record)=>{
            let typeTemp = Mode!=='soc'? '操作员':'普通用户';
            let type =104;
            if(record.usertype===0){
                if (state.systemuser.list.filter(x=>x.id===record.user).length>0){
                    type = state.systemuser.list.filter(x=>x.id===record.user)[0].type;
                }
               
            }else{
                type = record.usertype;
            }
            if (type>101){
                if(type===104){
                    typeTemp = Mode!=='soc'? '操作员':'普通用户';
                }else{
                    typeTemp = Mode!=='soc'? '技术员': '管理员';
                }
           }else{
                typeTemp = Mode!=='soc'? '管理员':'系统管理员';
           }
            return(
                <div>{typeTemp}</div>
            )
        },
        
    },
    {
        title: '公司名称',
        colSpan:Mode==='soc'?1: 0,
        width:Mode==='soc'?260: 0,
        dataIndex: 'corporatename',
        align: 'center',
        render:(text,record)=>{
            if(Mode==='soc'){
                if(record.username===''){
                    return(
                        <div>
                            {
                                state.systemuser.list.filter(x=>x.id===record.user).length>0?
                                state.systemuser.list.filter(x=>x.id===record.user)[0].title:
                                ''
                            }
                        </div>
                    )
                }else{
                    return(
                        <div>
                            {
                                state.systemuser.list.filter(x=>x.name===record.username).length>0?
                                state.systemuser.list.filter(x=>x.id===record.user)[0].title:
                                ''
                            }
                        </div>
                    )
                }
            }else{
                return(
                    <div></div>
                )
            }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={userSerch}
                        placeholder='公司名称'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => userSerch.current.focus({cursor: 'all'}), 100);
            }
            },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

        onFilter: (value, record) =>{
            let arrs =  state.systemuser.list.filter(x=>x.title.includes(value));
            if(arrs.length>0){
                let ids = [];
                arrs.map(m=>ids.push(m.id));
                return ids.includes(record.user);
            }
        }
      
        ,
    },
    {
        title: '用户名称',
        dataIndex: 'username',
        align: 'center',
        render:(text,record)=>{
            if(record.username===''){
                return(
                    <div>
                        {
                            state.systemuser.list.filter(x=>x.id===record.user).length>0?
                            state.systemuser.list.filter(x=>x.id===record.user)[0].name:
                            ''
                        }
                    </div>
                )
            }else{
                return(
                    <div>
                        {
                            record.username
                        }
                    </div>
                )
            }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) =>
            (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={userSerch}
                        placeholder='操作人'
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button size="small" style={{ width: 90 }} onClick={() => clearFilters()}>
                            重置
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            查找
                        </Button>

                    </Space>
                </div>
            ),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => userSerch.current.focus({cursor: 'all'}), 100);
            }
            },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,

        onFilter: (value, record) =>{
            let arrs =  state.systemuser.list.filter(x=>x.name.includes(value));
            if(arrs.length>0){
                let ids = [];
                arrs.map(m=>ids.push(m.id));
                return ids.includes(record.user);
            }
        }
      
        ,
    },
    {
        title: '操作模块',
        dataIndex: 'type',
        align: 'center',
        width:140,
        render: (text, record) => {
            let ttt =state.permissiontype.filter(x => { return x.code === record.type });
            return (
            <div>
                {
                    record.type===101?'登录':
                    ttt.length > 0 &&
                    ttt[0].value 
                }
            </div>
        )},
        filters:pType.length>0&&pType,
        onFilter:(value, record) => 
            record.type===value
    },

    {
        title: '操作内容',
        dataIndex: 'log',
        align: 'center',
        render: (text, record) =>{

            if(text.startsWith('/v1/')){
                let index = text.indexOf('{');
                let jsonTemp = JSON.parse(text.slice(index))
                let strF = text.slice(4,index).trim();
                return  <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' ,whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis',width:700}}>
                    {(()=>{
                            if('readdevicedata'===strF){
                                return <span>{`获取设备号:${jsonTemp.device},${jsonTemp.starttime}到${jsonTemp.endtime}之间的数据`}</span>
                            }
                            else if('getdevice'===strF){
                                if(jsonTemp.type===5||jsonTemp.type===20){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的报警参数`}</span>
                                }else if(jsonTemp.type===2||jsonTemp.type===27){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的时间`}</span>
                                }
                                else if(jsonTemp.type===3){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的上传频率`}</span>
                                }else if(jsonTemp.type===6){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的断电报警参数`}</span>
                                }else if(jsonTemp.type===8){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的超标报警参数`}</span>
                                }
                                else if(jsonTemp.type===9){
                                    return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的存储频率`}</span>
                                }
                                else if(jsonTemp.type===10||jsonTemp.type===28){
                                    if(!jsonTemp.node){
                                        return <span>{`获取设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的补偿参数`}</span>
                                    }else{
                                        return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的补偿参数`}</span>
                                    }
                                }else if(jsonTemp.type===12){
                                    if(!jsonTemp.node){
                                        return <span>{`获取设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的存储信息`}</span>
                                    }else{
                                        return <span>{`获取设备号:${jsonTemp.device},探头:${jsonTemp.node}的存储信息`}</span>
                                    }
                                }
                                else{
                                    return <span>{`获取设备参数`}</span>
                                }
                                
                            }else if('setdevice'===strF){
                                if(jsonTemp.type===5||jsonTemp.type===20){
                                    if(!jsonTemp.node){
                                        let str= `设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的报警参数。
                                        温度上限:${jsonTemp.device.Sensor.TempAlarmHigh},温度下限:${jsonTemp.device.Sensor.TempAlarmLow}`
                                        if(jsonTemp.device.Humi===1){
                                            str+=`湿度上限:${jsonTemp.device.Sensor.HumiAlarmHigh},湿度下限:${jsonTemp.device.Sensor.HumiAlarmLow}`
                                        }
                                        return <span>{str}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的报警参数`}</span>
                                    }
                                }else if(jsonTemp.type===3){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的上传间隔${jsonTemp.device.Params.RealTimeInt}秒
                                        `}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的上传间隔`}</span>
                                    }
                                }else if(jsonTemp.type===6){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的断电报警参数。
                                        断电时间:${jsonTemp.device.Params.PowerAlarmTimeCount}秒,报警间隔:${jsonTemp.device.Params.PowerAlarmMsgIntCount}分钟
                                        ,报警次数:${jsonTemp.device.Params.PowerAlarmMsgCount}次`}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的断电报警参数`}</span>
                                    }
                                }else if(jsonTemp.type===8){
                                    if(!jsonTemp.node){
                                        let str = `设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的超标报警参数。
                                        超标时间:${jsonTemp.device.Params.OverAlarm.Time}秒,报警间隔:${jsonTemp.device.Params.OverAlarm.TimeInt}分钟，
                                        温度报警次数:${jsonTemp.device.Params.OverAlarm.TempCount}次`;
                                        if (jsonTemp.device.Humi===1){
                                            str+=`湿度报警次数:${jsonTemp.device.Params.OverAlarm.HumiCount}次`;
                                        }
                                        return <span>{str}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的超标报警参数`}</span>
                                    }
                                }
                                else if(jsonTemp.type===9){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的存储间隔
                                        正常存储${jsonTemp.device.Params.NorStoreInt}分钟，报警存储${jsonTemp.device.Params.AlmStoreInt}分钟`}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的存储间隔`}</span>
                                    }
                                }else if(jsonTemp.type===2||jsonTemp.type===27){
                                    if(!jsonTemp.node){
                                        return <span>{`校正设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的时间`}</span>
                                    }else{
                                        return <span>{`校正设备号:${jsonTemp.device},探头:${jsonTemp.node}的时间`}</span>
                                    }
                                }else if(jsonTemp.type===10||jsonTemp.type===28){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的补偿参数`}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的补偿参数`}</span>
                                    }
                                }else if(jsonTemp.type===25||jsonTemp.type===11){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}关机`}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}关机`}</span>
                                    }
                                }
                                else if(jsonTemp.type===12){
                                    if(!jsonTemp.node){
                                        return <span>{`设置设备号:${jsonTemp.device.Sn},探头:${jsonTemp.device.Node}的存储信息`}</span>
                                    }else{
                                        return <span>{`设置设备号:${jsonTemp.device},探头:${jsonTemp.node}的存储信息`}</span>
                                    }
                                }
                                else{
                                    return <span>{`设置设备参数`}</span>
                                }
                            }else if(strF.startsWith('devicelink/')){
                                let str = ``;
                                let de = state.device.filter(x=>x.id==strF.slice(11,index).trim());
                                if (de.length>0){
                                    str = `设备号: ${de.sn} ,探头: ${de.node} ,名称为：${de.name} 的设备`;
                                } 
                                if(jsonTemp.alarm===0){
                                    str +="取消探头连接失败报警"
                                }else if(jsonTemp.alarm===1){
                                    str +="设置探头连接失败报警"
                                }
                                return <span>{str}</span>
                            }else if('setdevices'===strF){
                                if(jsonTemp.devices.type===3){
                                    return <span>{`批量设置上传间隔`}</span>
                                }else if(jsonTemp.devices.type===4){
                                    return <span>{`批量设置停用`}</span>
                                }else if(jsonTemp.devices.type===5){
                                    return <span>{`批量设置报警参数`}</span>
                                }else if(jsonTemp.devices.type===6){
                                    return <span>{`批量设置断电`}</span>
                                }else if(jsonTemp.devices.type===7){
                                    return <span>{`批量设置电量报警`}</span>
                                }else if(jsonTemp.devices.type===8){
                                    return <span>{`批量设置超标报警`}</span>
                                }else if(jsonTemp.devices.type===9){
                                    return <span>{`批量设置存储间隔`}</span>
                                }else if(jsonTemp.devices.type===2){
                                    return <span>{`批量校正设备时间`}</span>
                                }else if(jsonTemp.devices.type===10){
                                    return <span>{`批量设置补偿参数`}</span>
                                }else if(jsonTemp.devices.type===25||jsonTemp.devices.type===11){
                                    return <span>{`批量关机`}</span>
                                }else if(jsonTemp.devices.type===12){
                                    return <span>{`批量设置存储`}</span>
                                }
                                else{
                                    return <span>{`批量设置参数`}</span>
                                }
                            }
                            else if('sendsms'===strF){
                                return <span>{`${jsonTemp.node}发送报警短信`}</span>
                            }
                            else if('sendphone'===strF){
                                return <span>{`${jsonTemp.name}发送报警语音`}</span>
                            }
                            else if('sendwechat_new'===strF){
                                return <span>{`${jsonTemp.name}发送报警微信`}</span>
                            }
                            else if('device'===strF){
                                return <span>{`增加设备号: ${jsonTemp.device} ,探头: ${jsonTemp.node} ,名称: ${jsonTemp.name} 的设备`}</span>
                            }else if(strF.startsWith('devicefree/')){
                                let str = `设备号: ${jsonTemp.sn} ,探头: ${jsonTemp.node} , 的设备`;
                                if(jsonTemp.hide==0){
                                    str +="空库恢复正常"
                                }else if(jsonTemp.hide>1){
                                    str +="设置为空库"
                                }else {
                                    str +="设置为隐藏"
                                }
                                return <span>{str}</span>
                            }
                            else if(strF.startsWith('device/')){
                                return <span>{`设备号: ${jsonTemp.sn} ,探头: ${jsonTemp.node} ,名称: ${jsonTemp.name} 的设备`}</span>
                            }else if(strF.startsWith('devicewarning/')){
                                return <span>{`设置预警`}</span>
                            }else if(strF.startsWith('deviceswarning')){
                                return <span>{`批量设置预警`}</span>
                            }else if(strF.startsWith('devicesync')){
                                return <span>{`同步设备号: ${jsonTemp.sn} ,探头: ${jsonTemp.node} ,名称: ${jsonTemp.name} 的信息`}</span>
                            }else if(strF.startsWith('devicelink/')){

                                return <span>{`同步设备号: ${jsonTemp.sn} ,探头: ${jsonTemp.node} ,名称: ${jsonTemp.name} 的信息`}</span>
                            }else if('district'===strF){
                                return <span>{`增加名称: ${jsonTemp.name} 的区域`}</span>
                            }else if(strF.startsWith('district/')){
                                return <span>{`名称: ${jsonTemp.name} 的区域`}</span>
                            }
                            else if('users'===strF){
                                return <span>{`增加名称: ${jsonTemp.name}  登录账户 ${jsonTemp.account} 的用户`}</span>
                            }else if(strF.startsWith('users/')){
                                return <span>{`名称: ${jsonTemp.name}  登录账户 ${jsonTemp.account} 的用户`}</span>
                            }else if('dealalarm'===strF){
                                return <span>{`批处理报警`}</span>
                            }else if('dealalarms'===strF){
                                return <span>{`批处理报警`}</span>
                            }else if('alarmsuer'===strF){
                                return <span>{`增加名称: ${jsonTemp.name} 的报警联系人`}</span> 
                            }else if(strF.startsWith('alarmsuer/')){
                                return <span>{`名称: ${jsonTemp.name} 的报警联系人`}</span> 
                            }else if('devicelink'===strF){
                                return <span>{`设置探头连接`}</span>
                            }else if('setconfig'===strF){
                                return <span>{`本地配置`}</span>
                            }else if('startbackup'===strF){
                                return <span>{`开始备份`}</span>
                            }else if('layout'===strF){
                                return <span>{`增加名称: ${jsonTemp.name} 的区域`}</span>
                            }else if(strF.startsWith('layout/')){
                                return <span>{`名称: ${jsonTemp.name} 的区域`}</span>
                            }else if(strF==='devicesync'){
                                return <span>{`向本地同步信息`}</span>
                            }
                            else{
                                return <span>{text}</span>
                            }
                    })()}
                </div>
            }else{
                if(text.startsWith('{')){
                    let jsonTemp = JSON.parse(text)
                    let showData ='';
                    // if (jsonTemp.operator!==""){
                    //     showData+=`用户名称：${jsonTemp.operator}, `;
                    // }
                    if (jsonTemp.district!==""){
                        showData+=`区域名称：${jsonTemp.district}， `;
                    }
                    if(jsonTemp.type===0){
                        
                    }
                    showData+= `${jsonTemp.content}。${jsonTemp.result}`;
                    if (jsonTemp.contentData!=null){
                       return <div>
                           <span>{showData}</span>
                           <Button type="link" onClick={()=>{setShowDetailsModel(true);setDetails(jsonTemp.contentData);setLogType(jsonTemp.type)}}>参数详情</Button>
                       </div>
                    }else{
                       return <span>{showData}</span>
                    }
                }else{
                    return <span>{text}</span>
                }
                
            }
        }
           
    },
    {
        title: '电子签名',
        dataIndex: 'signa',
        align: 'center',
        width:100,
    }
    ]
    return (
        <div style={{backgroundColor:'#fff',position:'relative',height:'100%'}}>
            <div style={{ display: 'flex', alignItems: 'center', margin: 10, padding: 10, border:'1px solid #ccc',borderRadius:5 }}>
                {
                    Mode==='soc'&&<span>
                        <span margin-left='14px' style={{ fontSize: 14, marginLeft: 10 }}>选择用户</span>
                        <TreeSelect
                            allowClear
                            showSearch
                            treeData={state.systemuser.tree}
                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                            style={{ width: 300, marginLeft: 10 }}
                            placeholder="选择用户"
                            treeDefaultExpandAll
                            optionFilterProp="children"
                            onChange={(value) => {
                                value?setSelUser(value):setSelUser(0);
                            }}
                        />
                    </span>
                }

                <span style={{ fontSize: 14, marginLeft: 20 }}>操作类型</span>
                <Select
                    allowClear
                    showSearch
                    style={{ width: 200, marginLeft: 10 }}
                    placeholder="操作类型"
                    optionFilterProp="children"
                    onChange={(d) => {
                        console.log('操作类型',d);
                        d?setOType(d):setOType(0);
                    }}
                >
                    {state.permissiontype.filter(x=>x.code!==303).map(({ id, code,value }) => (
                        <Select.Option key={id} value={code}>{value}</Select.Option>
                    ))}
                </Select>
                <RangePicker style={{ marginLeft: 20 ,borderRadius:10}}
                    allowEmpty={[false,true]}
                    ranges={{
                        今天: [moment().startOf('day'), moment()],
                        '本周': [moment().startOf('week'), moment().endOf('week')],
                        '本月': [moment().startOf('month'), moment().endOf('month')],
                    }}
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    onChange={(dates, dateStrings) => {
                        if(dates===null){
                            setStartTime('');
                            setEndTime('');
                        }else{
                            setStartTime(dateStrings[0]+':00');
                            dates[1]!==null?setEndTime(dateStrings[1]+':00'):setEndTime(formatDateTimes(new Date().getTime()));
                        }   
                    }}
                />
                <div style={{ flex: 1 }}></div>
                <Button type={"primary"} style={{borderRadius:10}} onClick={() => {
                    let pam='?';
                    startTime!==''?pam+=`starttime=${startTime}&endtime=${endTime}`:pam+=`starttime=${formatDateTimes(new Date().getTime()-1000*60*60*24)}&endtime=${formatDateTimes(new Date().getTime())}`;
                    selUser>0&& (pam+=`&user=${selUser}`);
                    oType>0&& (pam+=`&type=${oType}`);
                    fetch("/v1/userlogpdf"+pam, { //downloadFiles 接口请求地址
                        method: 'get',
                        credentials: 'include',
                        headers: new Headers({
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        })
                    })
                    .then((response) => {
                        response.blob().then(blob => {
                            let blobUrl = window.URL.createObjectURL(blob);
                            let aElement = document.getElementById('downloadDiv'); //获取a标签元素
                            let filename = '操作日志.pdf';//设置文件名称
                            aElement.href = blobUrl;//设置a标签路径
                            aElement.download = filename;
                            aElement.click();
                            window.URL.revokeObjectURL(blobUrl);
                        });
                    }).catch((error) => {
                        console.log('文件下载失败', error);
                    });
                }}>导出</Button>

                <Button type={"primary"} style={{borderRadius:10}} onClick={() => {
                    let pam='?';
                   
                    // startTime!==''&& (pam.indexOf('?')===-1?pam+=`?starttime=${startTime}&endtime=${endTime}`:pam+=`&starttime=${startTime}&endtime=${endTime}`);
                    // pam===''&&(pam='?starttime='+formatDateTimes(new Date().getTime()-1000*60*60*24)+'&endtime='+formatDateTimes(new Date().getTime()));
                    startTime!==''?pam+=`starttime=${startTime}&endtime=${endTime}`:pam+=`starttime=${formatDateTimes(new Date().getTime()-1000*60*60*24)}&endtime=${formatDateTimes(new Date().getTime())}`;
                    selUser>0&& (pam+=`&user=${selUser}`);
                    oType>0&& (pam+=`&type=${oType}`);
                    http.get("/v1/userlog"+pam, {}).then(data => {
                        if (data.code === 0) {
                            let logData = data.data.filter(x=>x.type!==303)
                            if(state.main.type>101){
                                logData =logData.filter(x=>!(x.type===305&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1))&&!(x.type===301&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1||x.log.indexOf('settimesync')!==-1)))
                            }
                            
                            logData.sort((a,b)=>Date.parse(b.time)- Date.parse(a.time))
                            let temp = logData.filter(x=>!((x.type===305||x.type===901)&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1))&&!(x.type===301&&(x.log.indexOf('"type":10')!==-1||x.log.indexOf('"type":28')!==-1)||x.log.indexOf('settimesync')!==-1))
                            dispatch(
                                Mode!=='soc'?{ type: 'operation', operation:temp  }:
                                { type: 'operation', operation: logData })
                                setLogData(Mode!=='soc'?temp : logData)                           
                        }
                    })
                }}>查询</Button>
            </div>
            <div style={{margin: 10, padding: 10, border:'1px solid #ccc' ,maxHeight:'78vh',position:'absolute',width:'100%'}}>
                <Spin  spinning={isLoading} style={{marginLeft:'50%'}}/>
                <Table bordered pagination={{ showSizeChanger:true,hideOnSinglePage:true}}
                    rowKey={record => record.id} columns={signEnable?columns:columns.filter(x=>x.title!=='电子签名')} size='small'
                    style={{width:'100%'}}
                    dataSource={logData}
                    scroll={{y:state.resolution.height<800?state.resolution.height/2.2:state.resolution.height<1000?state.resolution.height/1.8:state.resolution.height/1.58}}
                />

                < Modal title="参数详情"
                    width='800px'
                    visible={showDetailsModel}
                    closable={false}
                    footer={[< Button key='ok'
                        onClick={
                            () => {
                                setShowDetailsModel(false)
                            }
                        } >
                        确定 </Button>
                    ]} >
                        
                        <div>
                            {
                                logType===0?
                                details.map(m=>{
                                return<div>
                                        <span style={{color:'#1890ff'}}>参数名称: </span> 
                                        {`${m.name} 。`}
                                        {/* <span style={{color:'#1890ff'}}>参数值: </span> 
                                        {` ${m.value} 。 `} */}
                                        
                                    </div>
                                })
                            :
                                logType===1?
                                details.map(m=>{
                                return<div>
                                        <span style={{color:'#1890ff'}}>参数名称: </span> 
                                        {`${m.name} ,`}
                                        <span style={{color:'#1890ff'}}>参数值: </span> 
                                        {` ${m.updata} 。 `}
                                    
                                    </div>
                                })
                            :
                                logType===2?
                                    details.map(m=>{
                                    return<div>
                                            <span style={{color:'#1890ff'}}>参数名称: </span> 
                                            {`${m.name} ,`}
                                            <span style={{color:'#1890ff'}}>修改前参数值: </span> 
                                            {` ${m.value} , `}
                                            <span style={{color:'#1890ff'}}>修改后参数值：</span> 
                                            {` ${m.updata}。`}
                                        </div>
                                    })
                                :
                                logType===3?
                                    details.map(m=>{
                                    return<div>
                                            <span style={{color:'#1890ff'}}>参数名称: </span> 
                                            {`${m.name} ,`}
                                            <span style={{color:'#1890ff'}}>参数值: </span> 
                                            {` ${m.value} 。 `}

                                        </div>
                                    })
                                :
                                <div></div>
                                // (()=>{
                                //     if(logType===2){
                                //         
                                //     }
                                // })()
                                
                            }
                        </div>
                </Modal>
            </div>
            {/* 下载区 */}
            <div style={{ marginTop: 10, textAlign: 'right' }}>
                        <a id="downloadDiv" style={{ display: 'none' }}></a>
            </div>
        </div>
    )
}

export default Operation